import React, { Suspense, lazy, useCallback, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "../styles/main.css";

import initializeFirebaseApp from "../services/firebase";

import App from "../components/App";
import getAPIRequest from "../utils/api";
import requestSnapshot from "../utils/requestSnapshot";

const Toggle = lazy(() => import("../components/Toggle/Toggle"));
const BottomBar = lazy(() => import("../components/BottomBar/BottomBar"));
const Activities = lazy(() => import("../components/Activities/Activities"));
const Selected = lazy(() => import("../components/Selected/Selected"));
const GuestProfile = lazy(() => import("../components/Selected/GuestProfile"));
const Lightbox = lazy(() => import("../components/Lightbox"));

const AppContainer = () => {
  const [ready, setReady] = useState(false);

  const [context, setContext] = useState({});

  const [newTicket, setNewTicket] = useState(null);

  const [focusedTicket, setFocusedTicket] = useState(null);

  const [lightbox, setLightbox] = useState(null);

  const [pendingComment, setPendingComment] = useState(null);

  const focusTicket = useCallback((ticket) => {
    setFocusedTicket(ticket);
  }, []);

  const addTicket = useCallback((payload) => {
    const {
      comment,
      description,
      video,
      attachments,
      querySelector,
      position,
      snapshotPayload,
      token,
      env,
      href,
      project,
    } = payload;

    if (!token) {
      window.parent.postMessage(
        { action: "toggle-profile", commentPayload: payload },
        "*"
      );

      return;
    }

    const commentID = `${Date.now()}${Math.random()}`.replace(".", "");

    const attachmentsToUpload = [];

    fetch(
      getAPIRequest({
        env,
        functionName: "appmain",
        body: {
          comment,
          description,
          commentID,
          projectID: project,
          querySelector,
          // dotPosition: position,
          dotPositionFromEl: position,
          href,
          attachments: attachments.map((file) => {
            const path = `attachments/${project}/${file.name}`;

            attachmentsToUpload.push({ path, base64: file.base64 });

            return {
              name: file.name,
              size: file.size,
              type: file.type,
              path: `gs://${env}.appspot.com/${path}`,
            };
          }),
          ...(video
            ? {
                videoURL: `gs://${env}.appspot.com/videos/${token}/${commentID}`,
                videoDuration: video.duration,
              }
            : {}),
          source: "blink",
        },
        requestPath: "tickets?action=create",
        headers: { token },
      })
    )
      .then((res) => res.json())
      .then((res) => {
        window.parent.postMessage(
          {
            action: "show-snackbar",
            message: "Ticket successfully added",
            type: "success",
          },
          "*"
        );

        setTimeout(() => {
          window.parent.postMessage(
            {
              action: "hide-snackbar",
            },
            "*"
          );
        }, 5000);

        requestSnapshot({
          ...snapshotPayload,
          ticketID: res.id,
          projectID: project,
          dotPosition: position,
          outlineElementSelector: querySelector,
        });

        if (video)
          fetch(video.base64data)
            .then((res) => res.blob())
            .then((blob) =>
              fetch(
                getAPIRequest({
                  env,
                  functionName: "apphighmem",
                  body: blob,
                  requestPath: `tickets?action=upload-video&path=${encodeURIComponent(
                    `videos/${token}/${commentID}`
                  )}&commentID=${commentID}&projectID=${project}`,
                  headers: { token },
                })
              ).then((r) => r.text())
            );

        if (attachmentsToUpload.length > 0)
          fetch(
            getAPIRequest({
              env,
              functionName: "apphighmem",
              body: {
                files: attachmentsToUpload,
                commentID,
                projectID: project,
              },
              requestPath: "tickets?action=upload-attachment",
              headers: { token },
            })
          ).then((res) => res.text());
      });

    setNewTicket(null);

    setPendingComment(null);
  }, []);

  useEffect(() => {
    const messageHandler = (e) => {
      // console.log(e.origin, window.location.origin, e.data);
      // if (e.origin !== window.location.origin) return;

      if (e.data?.fromRuttlBlink && e.data?.type) {
        switch (e.data.type) {
          case "init": {
            if (!ready) {
              initializeFirebaseApp(e.data.context.env);
              setContext(e.data.context);
              setReady(true);
            }

            break;
          }

          case "set-selected": {
            setNewTicket(e.data.selected);

            break;
          }

          case "focus-ticket": {
            focusTicket(e.data.ticket);

            break;
          }

          case "set-lightbox": {
            setLightbox({ source: e.data.source, type: e.data.sourceType });

            break;
          }

          case "set-pending-comment": {
            setPendingComment(e.data.commentPayload);

            break;
          }

          case "add-ticket": {
            addTicket(e.data.commentPayload);

            break;
          }

          default:
            break;
        }
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [addTicket, focusTicket, ready]);

  return ready || window.location.pathname === "/" ? (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: "/",
          element: (
            <div className="px-2 py-5 text-black/90 max-w-sm font-medium mx-auto">
              Hello, world! There's nothing for you here.
              <br />
              Please visit{" "}
              <a href="https://ruttl.com" className="text-blue">
                ruttl.com
              </a>{" "}
              to start.
            </div>
          ),
        },
        {
          path: "/start",
          element: (
            <Suspense fallback={null}>
              <Toggle />
            </Suspense>
          ),
        },
        {
          path: "/bottombar",
          element: (
            <App context={context}>
              {(props) => (
                <Suspense fallback={null}>
                  <BottomBar {...props} />
                </Suspense>
              )}
            </App>
          ),
        },
        {
          path: "/activities",
          element: (
            <App context={context}>
              {(props) => (
                <Suspense fallback={null}>
                  <Activities
                    {...props}
                    focusedTicket={focusedTicket}
                    focusTicket={focusTicket}
                  />
                </Suspense>
              )}
            </App>
          ),
        },
        {
          path: "/selected",
          element: (
            <App context={context}>
              {(props) => (
                <>
                  {newTicket && (
                    <Suspense fallback={null}>
                      <Selected
                        {...props}
                        {...newTicket}
                        addTicket={(payload) => {
                          window.parent.postMessage(
                            {
                              action: "add-ticket",
                              commentPayload: payload,
                            },
                            "*"
                          );

                          setNewTicket(null);
                        }}
                      />
                    </Suspense>
                  )}
                </>
              )}
            </App>
          ),
        },
        {
          path: "/profile",
          element: (
            <App context={context}>
              {(props) => (
                <Suspense fallback={null}>
                  <GuestProfile
                    {...props}
                    setGuestProfile={(uid) => {
                      setContext({ ...context, uid });

                      window.parent.postMessage(
                        { action: "set-guest-profile", uid },
                        "*"
                      );

                      if (pendingComment)
                        window.parent.postMessage(
                          {
                            action: "add-ticket",
                            commentPayload: pendingComment,
                          },
                          "*"
                        );

                      window.parent.postMessage(
                        { action: "toggle-profile" },
                        "*"
                      );
                    }}
                  />
                </Suspense>
              )}
            </App>
          ),
        },
        {
          path: "/lightbox",
          element: lightbox ? (
            <App context={context}>
              {(props) => (
                <Suspense fallback={null}>
                  <Lightbox {...props} {...lightbox} />
                </Suspense>
              )}
            </App>
          ) : (
            <></>
          ),
        },
      ])}
    />
  ) : (
    <></>
  );
};

export default AppContainer;
