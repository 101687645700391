const getAPIRequest = ({
  env = "rally-brucira",
  functionName = "apphighmem",
  body = {},
  headers = {},
  method = "POST",
  requestPath = "",
}) => {
  const projectID = env === "ruttlp" ? "uqolmctcuq" : "ypydobsv4a";

  const request = new Request(
    `https://${functionName}-${projectID}-uc.a.run.app/${requestPath}`,
    {
      headers,
      method,
      body: body instanceof Blob ? body : JSON.stringify(body),
    }
  );

  return request;
};

export default getAPIRequest;
