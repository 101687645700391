import { initializeApp } from "firebase/app";

const firebaseConfig = {
  ruttlp: {
    apiKey: "AIzaSyA72bt5vQbOm9wnJHyu20dN1OGKO-IVBzU",
    authDomain: "app.ruttl.com",
    databaseURL: "https://ruttlp.firebaseio.com",
    projectId: "ruttlp",
    storageBucket: "ruttlp.appspot.com",
    messagingSenderId: "554842701605",
    appId: "1:554842701605:web:f5b57c26e5f7dac515a4cc",
    measurementId: "G-TQDKVHRWQT",
  },
  "rally-brucira": {
    apiKey: "AIzaSyCUHR976KZGELxq8ZA8nHthKYY5pXT1Mwk",
    authDomain: "staging.ruttl.com",
    databaseURL: "https://rally-brucira.firebaseio.com",
    projectId: "rally-brucira",
    storageBucket: "rally-brucira.appspot.com",
    messagingSenderId: "595209566555",
    appId: "1:595209566555:web:d0bf60edff7153bf",
    measurementId: "G-HQXGN69ETV",
  },
};

const initializeFirebaseApp = (env) => initializeApp(firebaseConfig[env]);

export default initializeFirebaseApp;
