import getAPIRequest from "./api";

const requestSnapshot = async (snapshotPayload) => {
  const {
    html,
    env,
    windowScrollX,
    windowScrollY,
    baseURL,
    outlineElementSelector,
    dotPosition,
    projectID,
    ticketID,
    viewportHeight,
    viewportWidth,
  } = snapshotPayload;

  await fetch(
    getAPIRequest({
      env,
      functionName: "screenshot",
      body: {
        baseURL,
        html,
        scrollX: windowScrollX,
        scrollY: windowScrollY,
        viewportHeight,
        viewportWidth,
        outlineElementSelector,
        dotPosition,
        cloudStoragePath: `tickets/${projectID}-${ticketID}.jpg`,
        saveToCloudStorage: true,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
  );
};

export default requestSnapshot;
